$font-path: '../assets/font';

@font-face {
  font-family: 'TitilliumWeb';
  src: url('#{$font-path}/TitilliumWeb-Light.woff2') format('woff2'),
    url('#{$font-path}/TitilliumWeb-Light.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'TitilliumWeb';
  src: url('#{$font-path}/TitilliumWeb-Bold.woff2') format('woff2'),
    url('#{$font-path}/TitilliumWeb-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'OpenSans';
  src: url('#{$font-path}/OpenSans-Regular.woff2') format('woff2'),
    url('#{$font-path}/OpenSans.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'OpenSans';
  src: url('#{$font-path}/OpenSans-Bold.woff2') format('woff2'),
    url('#{$font-path}/OpenSans-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
}
