@import 'src/sass/core';

$color-palette-color-size: $input-height-base;
.color-pallete {
  .color-pallete-color {
    transition: transform 0.2s;
    transform: scale(1);
    border: 1px solid $input-border;
    display: inline-block;
    border-radius: $input-border-radius;
    height: $color-palette-color-size;
    width: $color-palette-color-size;
    position: relative;
    margin-right: 5px;
    &:hover {
      transform: scale(1.1);
    }
    &.active {
      transform: scale(1.25);
    }
  }
}
