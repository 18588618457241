.list {
  li {
    margin-bottom: $line-height-computed / 4;
    font-size: $font-size-base;

    a {
      display: block;
      color: $gray-dark;
      &:hover {
        color: $brand-primary;
      }
    }

    &.active {
      a {
        color: $brand-primary;
      }
    }
  }
}
