.progress {
  box-shadow: none;

  .progress-bar {
    box-shadow: none;
    transition: width 0.4s ease;
  }
}

.progress-bar {
  background: #2d577b;
}
