.main-header {
  background: #fff;
  display: flex;
  align-items: center;
  min-height: 90px;
  max-height: 90px;
  font-family: $font-family-alt;
  position: relative;
  color: $gray-dark;
  font-size: $font-size-xlarge;
  padding: $grid-gutter-width / 2;
  @media (max-width: $screen-lg) {
    min-height: 70px;
    max-height: 70px;
    font-size: $font-size-large;
    padding: $grid-gutter-width / 4 $grid-gutter-width / 2;
  }
  h1 {
    margin: 0;
    font-size: $font-size-4xlarge;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    padding: 2px 0;
    input {
      display: block;
      width: 100%;
      border: 2px dashed $brand-primary;
      color: $gray-darker;
    }
  }
  .save-btn {
    .icon {
      display: none;
      fill: #fff;
      width: 24px;
      height: 24px;
    }
    span {
      display: inline-block;
    }
    @media screen and (max-width: $screen-md) {
      .icon {
        display: inline-block;
      }
      span {
        display: none;
      }
      padding: 0;
      width: 48px;
    }
  }
  .back .icon {
    fill: $gray-darker;
    margin-right: 20px;
  }
  .icon {
    $size: 32px;
    width: $size;
    height: $size;
    fill: $gray;
    margin: 0 10px;
    &.active {
      fill: $brand-primary;
    }
  }
  .progress {
    $height: 3px;
    height: $height;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    margin-bottom: 0;
  }
  .selected-item {
    color: $gray;
    cursor: pointer;
    text-decoration: none;
    white-space: nowrap;
    > span {
      vertical-align: middle;
    }
  }
  .selected-item-icon-mobile {
    display: none;
  }
  @media screen and (max-width: $screen-sm) {
    .selected-item-label {
      display: none;
    }
    .selected-item-icon {
      display: none;
    }
    .selected-item-icon-mobile {
      display: inline-block;
    }
  }
  .cash-registers-popup-container {
  }
  .cash-registers-popup {
    position: absolute;
    top: 67px;
    right: 0;
    font-size: $font-size-large;
    background: $panel-body-bg;
    z-index: 5;
    min-width: 200px;
    border: 1px solid $gray;
    border-radius: $border-radius-base;
    overflow: scroll;
    -webkit-overflow-scrolling: touch;
    max-height: 370px;
    min-height: $min-height-loader-small;
    @media screen and (max-width: $screen-sm) {
      min-width: 0;
      width: 100%;
    }
    li {
      color: $text-color-contrast;
      background-color: $panel-body-bg;
      padding: ($padding-base-vertical * 2) ($padding-base-horizontal * 1.5);
      cursor: pointer;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      &:focus,
      &:hover {
        color: $gray-darker;
        background-color: $gray-lighter;
      }
      .icon {
        height: 24px;
        width: 24px;
        margin: 0 10px 0 0;
      }
    }
    span {
      padding: ($padding-base-vertical * 2) ($padding-base-horizontal * 2);
      display: block;
      white-space: nowrap;
    }
    .loader-container {
      position: static;
    }
  }
}
