#side-panel {
  $sidepanel-padding: $grid-gutter-width;
  background: $panel-body-bg;
  width: $side-panel-width;
  position: fixed;
  top: 0;
  right: 0;
  height: 100%;
  overflow-y: auto;
  transition: visibility 0.4s, right 0.4s ease;
  z-index: 101;
  padding: $sidepanel-padding;
  min-height: 100%;
  visibility: visible;

  &.sidepanel-hidden {
    right: -$side-panel-width;
    visibility: hidden;
  }

  @media screen and (max-width: $screen-sm) {
    $side-panel-width: 100%;
    width: $side-panel-width;

    &.sidepanel-hidden {
      right: -$side-panel-width;
    }
  }

  h2 {
    font-family: $font-family-alt;
    margin-top: 0;
    font-size: $font-size-3xlarge;
  }

  .alternative-content {
    background: $body-bg;
    margin-left: -$sidepanel-padding;
    margin-right: -$sidepanel-padding;
    padding: $sidepanel-padding;
  }
}

#side-panel-overlay {
  background: #26353b;
  position: fixed;
  left: 0;
  top: 0;
  right: 20px;
  bottom: 0;
  z-index: 100;
  opacity: 0.5;
  visibility: visible;
  transition: visibility 0.4s, opacity 0.4s ease;
  opacity: 0.5;

  &.overlay-hidden {
    visibility: hidden;
    opacity: 0;
  }
}
