.fieldset {
  margin-bottom: $grid-gutter-width / 2;

  &.fieldset-root {
    > legend {
      color: $gray;
      text-transform: uppercase;
      font-size: $font-size-3xlarge;
      padding-bottom: $font-size-3xlarge / 2;
    }
  }

  > legend {
    color: $brand-secondary-text;
    font-size: $font-size-large;
    padding-bottom: $font-size-large / 2;
  }
}
