.view-element {
  position: relative;
  .view-element-controls {
    position: absolute;
    top: $grid-gutter-width / 2;
    right: $grid-gutter-width / 2;
    background: $brand-primary;
    border-top: none;
    border-right: none;
    border-radius: $border-radius-base;
    z-index: 3;
    .icon {
      fill: #fff;
    }
  }
  .overlay {
    position: absolute;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
    background: rgba(255, 255, 255, 0.8);
    z-index: 3;
  }
  &.active {
    .overlay {
      border: 2px dashed $brand-primary;
      background-color: rgba(#fff, 0.7);
      background-image: linear-gradient(
        45deg,
        rgba(#ff9800, 0.15) 25%,
        transparent 25%,
        transparent 50%,
        rgba(#ff9800, 0.15) 50%,
        rgba(#ff9800, 0.15) 75%,
        transparent 75%,
        transparent
      );
      background-size: 40px 40px;
    }
  }
  .row-flex & {
    display: flex;
    width: 100%;
    .panel-body {
      width: 100%;
    }
  }
  .value-view {
    .value-icon {
      float: left;
      .icon {
        width: 32px;
        height: 32px;
        fill: $text-color-contrast;
      }
    }
    .value-content {
      margin-left: 44px;
      strong {
        font-size: 1.2em;
      }
    }
  }
}
