$collapsedWidth: 64px;
#side-nav {
  $width: 200px;
  flex: 0 0 $width;
  background: $side-panel-bg;
  $active: #fff;
  $not-active: $text-color-contrast;
  color: $not-active;
  transition: 0.4s;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  padding-top: 15px;
  &.nav-hidden {
    margin-left: -$width;
    &.menu-toggle {
      margin-left: -$collapsedWidth;
    }
  }
  label {
    color: $not-active;
  }
  .icon {
    fill: $not-active;
  }
  .menu-item-label {
    transition: 0.4s;
    opacity: 1;
  }
  li {
    white-space: nowrap;
  }
  &.menu-toggle {
    flex: 0 0 $collapsedWidth;
    overflow: hidden;
    .menu-item-label {
      opacity: 0;
    }
  }
  @media screen and (max-width: $screen-lg) {
    & {
      position: absolute;
      left: 0;
      z-index: 6;
      top: 44px;
      min-width: $width;
      bottom: 0;
      &.nav-hidden {
        margin-left: -$width;
      }
      &.menu-toggle {
        flex: 0 0 $collapsedWidth;
        max-width: $collapsedWidth;
        min-width: 0;
        position: static;
      }
    }
  }
  @media screen and (max-width: $screen-xs) {
    $width: 100%;
    & {
      min-width: $width;
      &.nav-hidden {
        margin-left: -$width;
      }
    }
  }
  .nav {
    a {
      color: $not-active;
      padding: 10px 10px;
      transition: border-left 0.4s;
      border-left: 5px solid $side-panel-bg;
      &:hover,
      &:focus,
      &.active {
        border-left: 5px solid #fff;
        color: $active;
        background-color: lighten($side-panel-bg, 10%);
        .icon {
          fill: $active;
        }
      }
    }
    .icon {
      margin-right: 16px;
      font-size: 16px;
      fill: $not-active;
      height: 32px;
      width: 32px;
    }
  }
  .checkbox {
    margin-top: 30px;
  }
  .menu-btn {
    cursor: pointer;
    .icon {
      width: 64px;
      height: 64px;
    }
  }
}

#content {
  transition: width 0.4s;
}

#content.menu-toggle {
  width: calc(100% - #{$collapsedWidth});
}
