.panel {
  border: none;
  background: none;
  box-shadow: 0 2px 0px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  .panel-dimmer {
    transition: 0.4s;
    background: transparent;
    z-index: 2;
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    pointer-events: none;
  }
  &.panel-dim {
    position: relative;
    .panel-dimmer {
      background: rgba(0, 0, 0, 0.5);
      pointer-events: auto;
    }
  }
  .panel-heading {
    font-size: $panel-heading-font-size;
    min-height: 38px; // Bulhari
    &.panel-separated {
      border-bottom: none;
      border-radius: $panel-heading-separated-border-radius;
    }
    > .subtitle {
      color: darken($panel-primary-text, 75%);
    }
  }
  .panel-body {
    background: $panel-body-bg;
    &.panel-separated {
      margin-top: $panel-body-separated-margin;
      border-radius: $border-radius-base;
    }
    hr {
      position: relative;
      left: -($panel-body-padding / 4);
      width: calc(100% / 2);
    }
  }
  .row-flex & {
    display: flex;
    width: 100%;
    .panel-body {
      width: 100%;
    }
  }
}

.panel-secondary {
  @include panel-variant(
    $panel-secondary-border,
    $panel-secondary-text,
    $panel-secondary-heading-bg,
    $panel-secondary-border
  );
}
