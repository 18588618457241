.table-licenses {
  border-spacing: 0 0;
  border-collapse: collapse;

  > thead {
    > tr > th {
      background: $panel-bg;
      color: $text-color;
      font-size: $font-size-large;
      padding: $grid-gutter-width / 2;
      text-align: center;
    }

    tr th:nth-child(1) {
      background: none;
      border-bottom: 1px solid transparent;
    }

    tr th:nth-child(2) {
      border-radius: $border-radius-base 0 0 0;
      .star {
        display: none;
      }
    }

    tr th:nth-child(3) {
      .star {
        display: none;
      }
    }

    tr th:nth-child(4) {
      .star:nth-child(1),
      .star:nth-child(2) {
        display: none;
      }
    }

    tr th:nth-child(5) {
      .star:nth-child(1) {
        display: none;
      }
    }

    tr th:last-child {
      border-radius: 0 $border-radius-base 0 0;
    }

    $licenses: (
      demo: (
        color: lighten($brand-secondary, 40%),
      ),
      basic: (
        color: lighten($brand-secondary, 30%),
      ),
      standard: (
        color: lighten($brand-secondary, 20%),
      ),
      premium: (
        color: lighten($brand-secondary, 10%),
      ),
      platinum: (
        color: lighten($brand-secondary, 0),
      ),
    );

    @each $lic, $map in $licenses {
      th.license-#{$lic} {
        border-bottom: 5px solid map-get($map, color);
      }
    }
  }

  > tbody {
    > tr > th {
      padding: $grid-gutter-width / 3 * 2 $grid-gutter-width / 2;
      text-align: right;
      vertical-align: middle;
      background: darken($panel-bg, 2%);
      border-bottom: 1px solid $body-bg;
      font-size: $font-size-large;
      font-weight: normal;
    }

    > tr > td {
      background: $panel-bg;
      padding: $grid-gutter-width / 3 * 2 $grid-gutter-width / 2;
      text-align: center;
      font-size: $font-size-large;
      border-bottom: 1px solid $body-bg;
      border-radius: 0 !important;
    }

    tr:first-child th:first-child {
      border-top-left-radius: $border-radius-base;
    }

    tr:last-child th:first-child {
      border-bottom-left-radius: $border-radius-base;
    }

    tr:last-child td:last-child {
      border-bottom-right-radius: $border-radius-base !important;
    }

    td.separator {
      background: $body-bg;
      padding: 0;
    }

    td.price {
      .amount {
        font-size: $font-size-xxlarge;
      }

      .details {
        margin-bottom: $grid-gutter-width / 2;
      }
    }
  }
}
