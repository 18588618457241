.search-control {
  $bg: #fff;
  .search-control-results-wrapper {
    position: relative;
    clear: both;
    z-index: 100010;
  }
  .search-control-results {
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.4);
    background: $bg;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    padding: 0;
    min-width: 220px;
  }
  .result-item {
    border-bottom: 1px solid #eee;
    padding: 6px $grid-gutter-width / 2;
    cursor: pointer;
    position: relative;
    &:focus,
    &.focus {
      color: #fff;
      background: $brand-primary;
    }
    &:last-child {
      border-bottom: none;
    }
    &.result-nothing {
      font-size: 14px;
      padding-top: $grid-gutter-width / 2;
      padding-bottom: $grid-gutter-width / 2;
    }
  }
}
