.alert {
  line-height: 2;
  .icon {
    fill: #fff;
  }
  .icon-main {
    width: 32px;
    height: 32px;
    margin: -4px 10px 0px 0px;
    float: left;
  }
  .close {
    color: #fff;
    opacity: 1;
  }
  &.alert-warning {
    background: #fff;
    color: $text-color;
    .icon {
      fill: $brand-warning;
    }
  }
}
