.login-form {
  $inputHeight: 40px;
  max-width: 450px;
  margin: 100px auto;
  transform: translate3d(0, 0, 0);
  @media screen and(max-width: $screen-xs) {
    margin: 50px auto;
  }

  .fieldset.fieldset-root > legend {
    color: $brand-secondary;
    border-bottom: 1px solid $brand-secondary;
  }

  .form-group {
    margin-bottom: 18px;
  }

  .panel-body {
    background: rgba(255, 255, 255, 0.9);
    padding: 25px 45px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    z-index: 1;
    position: relative;
    @media screen and(max-width: $screen-xs) {
      padding: 10px;
    }
  }

  .panel-heading {
    background: $logo-primary;
    border: none;
    z-index: 2;
    position: relative;
  }

  .form-control {
    background: transparent;
    border: 1px solid $logo-primary;
    height: $inputHeight;
  }

  .icon-container {
    border-radius: 50%;
    border: 1px solid $logo-primary;
    width: $inputHeight;
    display: inline-block;
    height: $inputHeight;
    padding-top: 9px;
    text-align: center;
    .icon {
      fill: $logo-primary;
      width: 18px;
      height: 18px;
    }
  }

  .panel-bookmark {
    background: $logo-primary;
    color: #fff;
    width: 55%;
    padding: 8px;
    margin-left: 32%;
    @media screen and(max-width: $screen-xs) {
      margin-left: 10%;
      width: 80%;
    }
    .icon-container {
      border: 1px solid #fff;
      .icon {
        fill: #fff;
      }
    }
  }

  .logo {
    .icon {
      width: 100%;
      fill: #fff;
      height: 55px;
    }
  }

  .panel {
    box-shadow: none;
    overflow: initial;
  }

  .btn {
    background: transparent !important;
    transition: background 0.4s;
    border-radius: 0;
    border: 1px solid $logo-primary !important;
    color: $logo-primary !important;
    text-transform: lowercase;
    font-size: 16px;
    &:hover,
    &:active {
      background: #fff !important;
    }
  }

  .panel-bookmark.recovery {
    padding: 0;
    text-align: center;
    .icon-container {
      margin-right: 8px;
      padding-top: 4px;
    }
    a {
      padding: 8px;
      color: #fff;
      width: 100%;
      height: 100%;
      display: block;
      font-size: 18px;
      text-decoration: none;
    }
    &:hover {
      background: lighten($logo-primary, 10%);
    }
  }

  .langswitch {
    float: left;
    color: $logo-primary;
    text-shadow: 0px 0px 2px #ffffff;
    background: rgba(255, 255, 255, 0.8);
    padding: 0.3em 0.5em;
    border-radius: 4px;
    font-size: 100%;
    position: relative;
    top: -40px;
    left: 10px;

    @media screen and(max-width: $screen-xs) {
      top: 30px;
      left: 50%;
      transform: translateX(-50%);
    }

    a,
    span {
      cursor: pointer;
      color: $logo-primary;
      font-weight: bold;
    }
  }
}

.forgot-form {
  max-width: 400px;
  margin: 100px auto 0 auto;
}

.registration-form {
  max-width: 100%;
  @media screen and(max-width: $screen-xs) {
    margin: $grid-gutter-width 0;
  }
}

.body-sky {
  display: none;
  position: fixed;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  transform: translate3d(0, 0, 0) scale3d(1, 1, 1);
  background: url(../assets/img/sky-bg.jpg) center center;
  background-repeat: no-repeat;
  background-size: cover;
  animation-name: slowlyZoom;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
  animation-duration: 120s;
  animation-fill-mode: forwards;
}

.scroll {
  .body-sky {
    display: block;
  }
}

@keyframes slowlyZoom {
  0% {
    transform: scale3d(1, 1, 1) rotateZ(0);
  }
  50% {
    transform: scale3d(2, 2, 2) rotateZ(0);
  }
  100% {
    transform: scale3d(1, 1, 1) rotateZ(0);
  }
}
