#status-bar {
  $verticalPadding: $grid-gutter-width / 4;
  $height: $verticalPadding * 2 + 34px;
  background: $top-bg;
  color: $gray-lighter;
  align-items: center;
  display: flex;
  font-size: $font-size-base;
  padding: $verticalPadding $grid-gutter-width / 2;
  height: $height;
  #logo {
    .icon {
      width: 122px;
      height: 26px;
      position: relative;
      top: -1px;
    }
  }
  .save-indicator {
    font-weight: bold;
    color: $brand-warning;
    margin-right: $grid-gutter-width / 2;
  }
  .icon {
    fill: $gray-lighter;
    height: 24px;
    width: 24px;
  }
  .menu-collapse-icon {
    margin-right: $grid-gutter-width / 2;
  }
  .picture-frame {
    cursor: pointer;
  }
  .profile {
    margin-left: $grid-gutter-width / 2;
    .name {
      vertical-align: middle;
      cursor: pointer;
    }
    .icon {
      margin-left: $grid-gutter-width / 2;
    }
  }
  .user-popup-container {
    position: relative;
    z-index: 7;
    @media screen and (max-width: $screen-sm) {
      position: absolute;
      left: $grid-gutter-width / 2;
      right: $grid-gutter-width / 2;
    }
  }
  .user-popup {
    position: absolute;
    top: $height / 2 + 12;
    left: 0;
    background: $top-bg;
    z-index: 5;
    width: 200px;
    font-size: $font-size-small;
    padding: 15px;
    border-radius: 4px;
    color: $text-color-contrast;
    hr {
      border-color: $gray-dark;
      margin: 10px 0;
    }
    h3 {
      font-size: $font-size-base;
      margin: 0;
      font-style: italic;
    }
    .arrow-up {
      width: 0;
      height: 0;
      border-left: 7px solid transparent;
      border-right: 7px solid transparent;
      border-bottom: 7px solid $top-bg;
      position: absolute;
      top: -7px;
      left: 12px;
    }
    ul {
      li {
        a {
          color: $text-color-contrast;
          fill: $text-color-contrast;
          padding: 4px 0;
          &:focus,
          &:hover {
            color: $gray-lighter;
            background-color: $top-bg;
            border-color: $top-bg;
            fill: $gray-lighter;
            svg.icon {
              fill: $gray-lighter;
            }
          }
          svg.icon {
            fill: $text-color-contrast;
            font-size: $font-size-small;
            margin-right: 10px;
            height: 16px;
          }
        }
      }
    }
    @media screen and (max-width: $screen-sm) {
      width: 100%;
      .arrow-up {
        left: auto;
        right: 60px;
      }
    }
  }
  @media screen and (max-width: $screen-sm) {
    &.hidden-on-mobile {
      display: none;
    }
  }
}
