@mixin form-control-validation-override(
  $text-color: #555,
  $border-color: #ccc,
  $background-color: #f5f5f5
) {
  // Color the label and help text
  .help-block,
  .control-label,
  .radio,
  .checkbox,
  .radio-inline,
  .checkbox-inline,
  &.radio label,
  &.checkbox label,
  &.radio-inline label,
  &.checkbox-inline label {
    color: $text-color;
  }
  // Set the border and box shadow on specific inputs to match
  .form-control {
    border: none;
    box-shadow: none;
    border-bottom: 2px solid lighten($brand-danger, 10%);
    &:focus {
      border: none;
      box-shadow: none;
      border-bottom: 2px solid $brand-danger;
    }
  }
  // Set validation states also for addons
  .input-group-addon {
    color: $text-color;
    border-color: $border-color;
    background-color: $background-color;
  }
  // Optional feedback icon
  .form-control-feedback {
    color: $text-color;
  }
}

// Feedback states
.has-success {
  @include form-control-validation-override(
    $state-success-bg,
    $state-success-text,
    $state-success-bg
  );
}

.has-warning {
  @include form-control-validation-override(
    $state-warning-bg,
    $state-warning-text,
    $state-warning-bg
  );
}

.has-error {
  @include form-control-validation-override(
    $state-danger-text,
    $state-danger-border,
    $state-danger-bg
  );
}

label {
  font-weight: normal;
  color: $gray-darker;
}

.form-control {
  box-shadow: none;
  border: none;
  border-bottom: 2px solid $input-border;
  font-size: $font-size-base;
  padding: $padding-base-vertical $padding-base-vertical;
  &:focus {
    border-color: none;
    outline: 0;
    box-shadow: none;
  }
}

.form-horizontal {
  @media (min-width: $screen-sm-min) {
    .control-label {
      text-align: left;
    }
  }
}

.checkbox-inline {
  padding-left: 0;
  margin-right: 10px;
}

.radio-inline + .radio-inline,
.checkbox-inline + .checkbox-inline {
  margin-top: 0;
  margin-left: 0;
}

.checkbox,
.checkbox-inline {
  label {
    padding-left: 6px;
    cursor: pointer;
  }
  input {
    opacity: 0;
    &[type='checkbox'] {
      pointer-events: none;
      + label {
        padding-left: 0;
        vertical-align: middle;
      }
      + label .checkbox-unchecked {
        display: inline-block;
      }
      + label .checkbox-checked {
        display: none;
      }
      &:checked {
        + label .checkbox-unchecked {
          display: none;
        }
        + label .checkbox-checked {
          display: inline-block;
        }
      }
    }
  }
}
