.fab {
  position: fixed;
  bottom: $grid-gutter-width;
  right: $grid-gutter-width;
  z-index: 2;
  .btn {
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.26);
  }
}

.fab-2 {
  right: $grid-gutter-width + 70px;
}

.fbtns{
  position: fixed;
  bottom: $grid-gutter-width;
  right: $grid-gutter-width;
  z-index: 2;
  .btn {
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.26);
    margin-left: 20px;
    float: right;
  }
  &::before,
  &::after {
    display: table;
    content: "";
  }
  &::after {
    clear: both;
  }
}
