.input-group-addon {
  background: #fff;
  border: none;
  border-bottom: 2px solid $input-border;
}

.input-group-btn {
  .btn {
    min-width: 0;
    top: 0;
    border: none;
    border-radius: 0;
    border-bottom: 2px solid $input-border;
    @include transition(
      border-color ease-in-out 0.15s,
      box-shadow ease-in-out 0.15s
    );
  }
}

.input-group {
  .form-control {
    position: static;
    z-index: auto;
  }
}
