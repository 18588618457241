@mixin button-variant-override($color, $background, $border) {
  color: $color;
  background-color: $background;
  border-color: $border;
  &:focus,
  &.focus {
    color: $color;
    background-color: darken($background, 10%);
    border-color: darken($border, 10%);
    .icon {
      fill: $color;
    }
  }
  &:hover {
    color: $color;
    background-color: darken($background, 5%);
    border-color: darken($border, 5%);
    .icon {
      fill: $color;
    }
  }
  &:active,
  &.active,
  .open > &.dropdown-toggle {
    color: $color;
    background-color: darken($background, 10%);
    border-color: darken($border, 10%);
    .icon {
      fill: $color;
    }
    &:hover,
    &:focus,
    &.focus {
      color: $color;
      background-color: darken($background, 17%);
      border-color: darken($border, 17%);
      .icon {
        fill: $color;
      }
    }
  }
  &:active,
  &.active,
  .open > &.dropdown-toggle {
    background-image: none;
  }
  &.disabled,
  &[disabled],
  fieldset[disabled] & {
    &:hover,
    &:focus,
    &.focus {
      background-color: $background;
      border-color: $border;
    }
  }
  .badge {
    color: $background;
    background-color: $color;
  }
  .icon {
    fill: $color;
  }
}

@mixin button-size-override(
  $height,
  $padding-horizontal,
  $font-size,
  $line-height,
  $border-radius,
  $icon-size,
  $border-width: 1px
) {
  //padding: $padding-vertical $padding-horizontal;
  padding: 0 $padding-horizontal;
  font-size: $font-size;
  line-height: $line-height;
  border-radius: $border-radius;
  border-width: $border-width;
  height: $height;
  display: inline-flex;
  align-items: center;
  //min-width: 110px;
  .icon {
    position: relative;
    height: $icon-size;
    width: $icon-size;
    &.icon-push-right {
      right: -($padding-horizontal / 3);
    }
    &.icon-push-left {
      left: -($padding-horizontal / 3);
    }
  }
  &.btn-circle {
    //border-radius: 50%;
    border-radius: 4px;
    padding: 0;
    width: $height;
    min-width: 0;
    .icon {
      margin: auto;
    }
  }
}

.btn {
  @include button-size-override(
    $input-height-base,
    $padding-base-horizontal,
    $font-size-base,
    $line-height-base,
    $btn-border-radius-base,
    18px
  );
  text-transform: uppercase;
  font-weight: 400;
  justify-content: center;
  &:active,
  &.active {
    box-shadow: none;
  }
}

.btn-sm {
  @include button-size-override(
    22px,
    $padding-base-horizontal,
    $font-size-small,
    $line-height-base,
    $btn-border-radius-base,
    16px
  );
}

.btn-lg {
  @include button-size-override(
    50px,
    $padding-base-horizontal * 3,
    $font-size-large,
    $line-height-base,
    $btn-border-radius-large,
    28px,
    2px
  );
}

.btn-default {
  @include button-variant-override(#fff, $gray-dark, $gray-dark);
}

.btn-default-o {
  @include button-variant-override($gray-dark, #fff, $gray-dark);
}

.btn-danger {
  @include button-variant-override(#fff, $brand-danger, $brand-danger);
}

.btn-danger-o {
  @include button-variant-override($brand-danger, #fff, $brand-danger);
}

.btn-primary {
  @include button-variant-override(#fff, $brand-primary, $brand-primary);
}

.btn-primary-o {
  @include button-variant-override($brand-primary, #fff, $brand-primary);
}

.btn-secondary {
  @include button-variant-override(#fff, $brand-secondary, $brand-secondary);
}

.btn-secondary-o {
  @include button-variant-override($brand-secondary, #fff, $brand-secondary);
}

.btn-group {
  .btn {
    font-weight: normal;
    background: $gray-lighter;
    border-radius: $border-radius-base;
    //margin: 0 $grid-gutter-width / 4; // Separated btns
    //font-size: $font-size-base;
    color: $gray-dark;
    min-width: 0;
    &.active {
      background: $brand-secondary;
      color: #fff;
      .icon {
        fill: #fff;
      }
    }
  }
  &.btn-group-xs {
    .btn {
      height: auto;
    }
  }
  .btn + .btn,
  .btn + .btn-group,
  .btn-group + .btn,
  .btn-group + .btn-group {
    margin-left: 0;
  }
  // Separated btns
  //    > .btn:not(:first-child):not(:last-child):not(.dropdown-toggle) {
  //        border-radius: $border-radius-base;
  //    }
  //
  //    > .btn:first-child {
  //        &:not(:last-child):not(.dropdown-toggle) {
  //            border-radius: $border-radius-base;
  //        }
  //    }
  //
  //    > .btn:last-child:not(:first-child),
  //    > .dropdown-toggle:not(:first-child) {
  //        border-radius: $border-radius-base;
  //    }
}
