$font-size-xlarge: ceil(($font-size-base * 1.25)) !default; // ~18px
$font-size-xxlarge: ceil(($font-size-base * 1.5)) !default; // ~21px
$font-size-3xlarge: ceil(($font-size-base * 1.75)) !default; // ~25px
$font-size-4xlarge: ceil(($font-size-base * 2)) !default; // ~28px

$font-size-xsmall: ceil(($font-size-base * 0.7)) !default;

$panel-heading-font-size: $font-size-base;
$panel-heading-separated-border-radius: $border-radius-base;
$panel-body-separated-margin: ($grid-gutter-width / 4);
$panel-body-bg: #fff;
$side-panel-bg: $gray-dark;
$top-bg: $gray-darker;
$font-family-alt: 'TitilliumWeb' !default;
$side-panel-width: 600px;
$min-height-loader: 80px;
$min-height-loader-small: 50px;

$line-height-computed: floor(
  ($font-size-large * $line-height-base)
) !default; // ~20px

$color-success: #00a65a;
$color-danger: #ff9800;
$color-error: #dd4b39;
