.material-input {
  $transitionSpeed: 240ms;

  &.focused {
    .control-label {
      color: $text-color;
      opacity: 0.7;
      transform: translate3d(0, -14px, 0) scale(0.8);
    }
  }

  &.focused-input {
    .control-label {
      color: $brand-primary;
      opacity: 1;
    }
  }

  .control-label {
    color: $text-color;
    font-size: $font-size-base;
    font-weight: normal;
    opacity: 0.5;
    pointer-events: none;
    position: absolute;
    transform: translate3d(0, 0, 0) scale(1);
    transform-origin: left top;
    transition: $transitionSpeed;
  }

  .form-control::-webkit-input-placeholder {
    color: transparent;
    transition: $transitionSpeed;
  }

  .form-control:focus::-webkit-input-placeholder {
    transition: none;
  }

  &.focused .form-control::-webkit-input-placeholder {
    color: lighten($text-color, 50%);
  }

  &.datepicker {
    position: absolute;
    left: 0;
    top: 0;
    pointer-events: none;

    .control-label {
      pointer-events: auto;
    }

    .form-control {
      visibility: hidden;
    }
  }
}
