$bradius: $panel-heading-separated-border-radius;
.table {
  border-spacing: 0 $grid-gutter-width / 6;
  border-collapse: separate;
  %rowBorderRadius {
    &:first-child {
      border-radius: $bradius 0 0 $bradius;
    }
    &:last-child {
      border-radius: 0 $bradius $bradius 0;
    }
  }
  tr.muted {
    td:first-child {
      border-left: 5px solid #333;
    }
    td {
      color: #aaa;
      > .icon {
        fill: #aaa;
      }
    }
  }
  &.table-sm {
    th,
    td {
      font-size: $font-size-small;
      padding: $panel-heading-padding-sm;
      &.font-lg {
        font-size: $font-size-base;
      }
    }
  }
  > thead {
    > tr > th {
      background: #2d577b;
      font-size: $panel-heading-font-size;
      color: $panel-secondary-text;
      font-weight: normal;
      padding: $panel-heading-padding;
      border-bottom: none;
      vertical-align: middle;
      @extend %rowBorderRadius;
    }
  }
  > tbody {
    > tr > td {
      background: $panel-body-bg;
      border-top: none;
      padding: $panel-heading-padding;
      vertical-align: middle;
      @extend %rowBorderRadius;
    }
  }
  &.table-hover > tbody > tr:hover {
    td {
      background: darken($panel-body-bg, 3%);
      cursor: pointer;
    }
  }
  thead th .check-all label {
    display: none;
  }
}

.panel-body .table td {
  background: #f9f9f9;
}

.table-responsive {
  @media screen and (max-width: $screen-xs-max) {
    border: none;
  }
}

.table-respo {
  @media screen and (max-width: $screen-sm) {
    width: 100%;
    margin: 0;
    padding: 0;
    border-collapse: collapse;
    border-spacing: 0;
  }
  thead {
    @media screen and (max-width: $screen-sm) {
      display: none;
    }
    a {
      text-decoration: underline;
      color: #fff;
      .icon {
        fill: #fff;
      }
    }
  }
  tr {
    @media screen and (max-width: $screen-sm) {
      margin-bottom: $grid-gutter-width;
      display: block;
      box-shadow: 0 8px 15px rgba(0, 0, 0, 0.2);
      &.muted td:first-child {
        border-left: none;
      }
    }
  }
  td {
    @media screen and (max-width: $screen-sm) {
      display: flex;
      clear: both;
      border-bottom: 1px dotted #ddd;
      &:first-child:before {
        border-radius: $bradius 0 0 0;
      }
      &:first-child {
        border-radius: 0 $bradius 0 0 !important;
      }
      &:last-child {
        border-bottom: none;
      }
      &:last-child:before {
        border-radius: 0 0 0 $bradius !important;
        border-bottom: none;
      }
    }
  }
  td:before {
    display: none;
    @media screen and (max-width: $screen-sm) {
      content: attr(data-label);
      display: block;
      max-width: 40%;
      min-width: 40%;
      text-align: left;
      margin: -10px 16px -11px -16px;
      border-bottom: 1px dotted #dfe4eb;
      background: $panel-secondary-heading-bg;
      font-size: $panel-heading-font-size;
      color: $panel-secondary-text;
      font-weight: normal;
      padding: $panel-heading-padding;
      vertical-align: middle;
    }
  }
}
